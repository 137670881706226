import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'

export const def = () => {
  return {
    id: 'vegan',
    name: 'vegan',
    category: 'misc',
    path: (
      <>
        <path
          clipRule="evenodd"
          d="M78.7332 41.4893C77.5903 39.4725 77.4559 37.0523 78.4643 34.9683C79.876 32.0103 79.0021 28.448 76.3802 26.4311C74.5651 25.0187 73.4895 22.8002 73.6239 20.5145C73.8256 17.2204 71.6071 14.2624 68.4475 13.4557C66.229 12.8506 64.4138 11.2372 63.6744 9.08594C62.5987 5.9935 59.5063 4.11115 56.2122 4.51451C53.9265 4.85064 51.6407 3.97669 50.0945 2.29602C47.876 -0.123476 44.2458 -0.729191 41.4223 0.951482C39.4054 2.09434 36.9853 2.22946 34.9012 1.22106C31.9433 -0.191375 28.3802 0.682574 26.3634 3.30442C24.9517 5.11955 22.7332 6.19518 20.4475 6.06073C17.1533 5.85904 14.1954 8.07753 13.3886 11.2372C12.7836 13.4557 11.1702 15.2708 9.01889 16.011C5.99368 17.0859 4.11133 20.1784 4.51469 23.4725C4.85082 25.7589 3.97687 28.0439 2.2962 29.5901C-0.123966 31.8093 -0.729008 35.4389 0.951664 38.2624C2.09452 40.2792 2.22897 42.7001 1.22057 44.7834C-0.191193 47.7421 0.682756 51.3051 3.30461 53.3219C5.11973 54.733 6.19536 56.9515 6.06091 59.2372C5.85923 62.532 8.07771 65.4893 11.2374 66.296C13.4559 66.9017 15.271 68.5145 16.0105 70.6658C17.0861 73.7582 20.2458 75.7078 23.4727 75.2372C25.7584 74.9011 28.0441 75.775 29.5903 77.4557C31.8088 79.8765 35.4391 80.4809 38.2626 78.8002C40.2794 77.658 42.6996 77.5236 44.7836 78.532C47.7416 79.9431 51.3046 79.0691 53.3214 76.4473C54.7332 74.6328 56.9517 73.5565 59.2374 73.691C62.5315 73.8927 65.4895 71.6742 66.2962 68.5145C66.9012 66.296 68.5147 64.4809 70.666 63.7421C73.7584 62.6658 75.6407 59.5068 75.2374 56.2792C74.9012 53.9942 75.7752 51.7078 77.4559 50.1616C79.876 47.9438 80.4138 44.3128 78.7332 41.4893Z"
          fill="currentColor"
          fillRule="evenodd"
        />

        <path
          clipRule="evenodd"
          d="M37.465 45C37.3099 44.9999 37.1592 44.948 37.0368 44.8525C36.9145 44.757 36.8276 44.6234 36.7898 44.4729C36.453 42.9369 36.3367 41.3607 36.4444 39.7919C36.6748 36.3195 38.0152 33.4806 40.3206 31.5825C40.4157 31.5043 40.5298 31.4526 40.6513 31.4328C40.7728 31.4129 40.8974 31.4256 41.0125 31.4695C42.4633 31.9686 43.9795 32.2518 45.5127 32.3101C48.7397 32.4194 51.2315 31.4022 52.9205 29.2888C56.4784 24.8375 55.4624 17.8217 55.0749 15.8073C53.2023 15.9385 47.1532 16.6521 43.8322 20.4563C42.1393 22.3954 41.3838 24.8886 41.581 27.8769C43.1946 27.2968 46.8001 25.7864 48.3362 23.4169C48.386 23.3401 48.4504 23.2739 48.5258 23.2219C48.6012 23.17 48.6861 23.1335 48.7757 23.1144C48.8652 23.0953 48.9576 23.094 49.0476 23.1106C49.1376 23.1272 49.2235 23.1614 49.3003 23.2112C49.3771 23.261 49.4433 23.3254 49.4952 23.4008C49.5472 23.4762 49.5837 23.5611 49.6028 23.6506C49.6219 23.7402 49.6232 23.8326 49.6066 23.9226C49.59 24.0126 49.5558 24.0985 49.506 24.1753C47.2223 27.6977 41.4101 29.4137 41.1637 29.485C41.0668 29.5131 40.9649 29.5198 40.865 29.5046C40.7652 29.4895 40.6699 29.4528 40.5857 29.3972C40.5014 29.3415 40.4303 29.2683 40.3772 29.1824C40.324 29.0966 40.2902 29.0002 40.2779 28.9C39.816 25.1181 40.66 21.9671 42.7862 19.534C47.1439 14.548 55.2802 14.3884 55.6245 14.384H55.6333C55.7888 14.384 55.9398 14.436 56.0623 14.5317C56.1848 14.6273 56.2718 14.7612 56.3095 14.912C56.4056 15.2968 58.6123 24.4001 54.0092 30.1588C52.0504 32.6095 49.0825 33.8339 45.4235 33.7013C43.892 33.6386 42.3758 33.3721 40.9148 32.9088C36.4221 36.9585 38.1237 44.0613 38.1417 44.1335C38.1672 44.2363 38.169 44.3436 38.1469 44.4472C38.1248 44.5508 38.0794 44.648 38.0141 44.7314C37.9489 44.8148 37.8655 44.8823 37.7703 44.9287C37.6751 44.9752 37.5705 44.9993 37.4646 44.9993L37.465 45Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          d="M35.3253 35.6051H35.3139C35.0337 35.6006 28.4159 35.4316 25.225 30.0479C22.0321 24.6609 26.0696 17.0031 26.243 16.6798C26.3174 16.5411 26.4366 16.4317 26.5812 16.3693C26.7257 16.3069 26.8871 16.2953 27.0391 16.3363C27.2552 16.3948 32.3768 17.8169 36.3239 22.1872C38.0531 24.1015 38.682 26.5765 38.1431 29.3448C37.8969 30.6039 37.4483 31.8148 36.8148 32.9305C36.7635 33.0147 36.6949 33.087 36.6135 33.1427C36.5321 33.1984 36.4399 33.2362 36.3428 33.2537C36.2457 33.2711 36.1461 33.2677 36.0504 33.2437C35.9548 33.2198 35.8653 33.1758 35.7879 33.1147C35.5673 32.9405 30.3714 28.8024 29.083 24.6508C29.0547 24.5631 29.0442 24.4706 29.0519 24.3788C29.0596 24.287 29.0855 24.1976 29.128 24.1158C29.1705 24.034 29.2287 23.9615 29.2995 23.9024C29.3702 23.8433 29.4519 23.7988 29.5399 23.7715C29.6279 23.7442 29.7205 23.7346 29.8122 23.7433C29.904 23.752 29.9931 23.7788 30.0744 23.8222C30.1557 23.8655 30.2277 23.9246 30.286 23.9959C30.3443 24.0673 30.388 24.1495 30.4143 24.2378C31.3222 27.1632 34.5901 30.2456 35.9986 31.4718C36.3549 30.6942 36.6181 29.8772 36.7826 29.0379C37.2266 26.7011 36.7246 24.7111 35.2895 23.1218C32.3454 19.8623 28.6008 18.3371 27.2186 17.857C26.4087 19.6004 24.0691 25.3632 26.4243 29.3374C29.2228 34.0581 35.2755 34.2105 35.336 34.2115C35.5208 34.2129 35.6976 34.2878 35.8272 34.4195C35.9569 34.5513 36.029 34.7292 36.0275 34.914C36.026 35.0989 35.9512 35.2756 35.8194 35.4053C35.6877 35.535 35.5098 35.607 35.3249 35.6055L35.3253 35.6051Z"
          fill="white"
        />
        <path
          d="M45.6749 39.2044C44.1059 39.2044 42.0146 38.7397 40.0994 36.8093C40.0235 36.7329 39.9665 36.6399 39.9326 36.5377C39.8987 36.4355 39.889 36.3269 39.9042 36.2203C39.9193 36.1137 39.959 36.012 40.02 35.9233C40.081 35.8347 40.1618 35.7613 40.2559 35.709C41.2106 35.2249 42.2499 34.93 43.3165 34.8407C44.9515 34.7007 47.3358 35.0208 49.4121 37.3323C49.4832 37.4115 49.5352 37.5059 49.5641 37.6082C49.5931 37.7106 49.5982 37.8182 49.5792 37.9229C49.5601 38.0275 49.5174 38.1264 49.4542 38.212C49.391 38.2976 49.3091 38.3676 49.2146 38.4166C48.2712 38.8601 47.2524 39.1212 46.212 39.1861C46.0412 39.1977 45.8617 39.2044 45.6749 39.2044ZM41.8944 36.5326C44.0701 38.1707 46.3632 37.8859 47.6158 37.5322C45.487 35.7721 43.1661 36.1243 41.8944 36.5326Z"
          fill="white"
        />
        <path
          d="M38.9182 22.4553C38.7799 22.4553 38.6448 22.4142 38.53 22.3372C37.6638 21.7082 36.9275 20.9177 36.3615 20.0092C35.4923 18.6177 34.6893 16.3499 35.7999 13.448C35.8379 13.3486 35.8982 13.2592 35.9761 13.1867C36.054 13.1142 36.1474 13.0606 36.2493 13.0298C36.3511 12.999 36.4587 12.992 36.5637 13.0092C36.6687 13.0265 36.7684 13.0675 36.8551 13.1292C37.68 13.7667 38.3769 14.5543 38.9092 15.4507C39.7374 16.8305 40.526 19.0824 39.5804 21.9748C39.5347 22.1145 39.446 22.2362 39.327 22.3224C39.208 22.4087 39.0648 22.4552 38.9179 22.4552L38.9182 22.4553ZM36.798 14.9463C36.6241 15.6801 36.6006 16.4416 36.7288 17.1848C36.8571 17.9279 37.1344 18.6375 37.5441 19.2706C37.8228 19.7143 38.1495 20.126 38.5183 20.4982C38.992 17.794 37.6894 15.8928 36.798 14.9463Z"
          fill="white"
        />
        <path
          d="M19.0526 60.06H20.6846L24.2366 51.264H22.2446L19.8926 57.528L17.5406 51.264H15.5006L19.0526 60.06ZM26.2137 60H32.8377V58.356H28.0377V56.424H32.2137V54.78H28.0377V52.908H32.7777V51.264H26.2137V60ZM39.3626 60.144C40.9466 60.144 42.1826 59.532 43.0826 58.764V54.996H39.3026V56.592H41.2946V57.924C40.7906 58.284 40.1426 58.476 39.4226 58.476C37.8266 58.476 36.7106 57.264 36.7106 55.632V55.608C36.7106 54.084 37.8506 52.812 39.2906 52.812C40.3106 52.812 40.9226 53.148 41.6066 53.712L42.7706 52.308C41.8586 51.54 40.9106 51.12 39.3506 51.12C36.6986 51.12 34.7786 53.16 34.7786 55.632V55.656C34.7786 58.236 36.6386 60.144 39.3626 60.144ZM48.1575 56.376L49.3935 53.412L50.6175 56.376H48.1575ZM44.7975 60H46.6815L47.4975 58.008H51.2775L52.0935 60H54.0255L50.2695 51.204H48.5655L44.7975 60ZM56.0144 60H57.8264V54.312L62.1704 60H63.7424V51.264H61.9184V56.772L57.7064 51.264H56.0144V60Z"
          fill="white"
        />
      </>
    ),
    viewBox: '0 0 80 80',
  }
}
export const IconVegan = makeIcon(def())
export default IconVegan
